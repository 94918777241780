import { Routes } from "@angular/router";
import { AuthGuard } from "@services/auth.guard";

export const routes: Routes = [
  { path: "login", loadComponent: () => import("@worker/login/login.component").then((x) => x.LoginComponent), canActivate: [AuthGuard] },
  { path: "login/reset", loadComponent: () => import("@worker/login/login.component").then((x) => x.LoginComponent) },
  {
    path: "logout",
    loadComponent: () => import("@worker/login/login.component").then((x) => x.LoginComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
  },
  {
    path: "crm",
    loadChildren: () => import("./modules/crm.routes").then((m) => m.standardRoutes),
    data: { animation: "isRight", panel_base: "crm" },
  },
  {
    path: "cms",
    loadChildren: () => import("./modules/cms.routes").then((m) => m.standardRoutes),
    data: { animation: "isLeft", panel_base: "cms" },
  },
  {
    path: "administration_system",
    loadChildren: () => import("./modules/admin.routes").then((m) => m.standardRoutes),
    data: { animation: "isCenter", panel_base: "administration_system" },
  },
  {
    path: "modules",
    loadChildren: () => import("./modules/modules.module").then((m) => m.ModulesModule),
    data: { animation: "isCenter", panel_base: "modules" },
  },


  { path: "", redirectTo: "login", pathMatch: "full" },
  // { path: "**", redirectTo: "logout", pathMatch: "full" },
  /* { path: '**', redirectTo: '/crm' },  */
];
//login/reset?token="123"&action=reset
//login/reset?token="123"&action=set